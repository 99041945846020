import { createChart, ColorType, IChartApi, LineStyle } from 'lightweight-charts';
import { FC, useEffect, useRef, useState } from 'react';
import { theme } from '../../themes/theme';
import { CircleLoading } from '../circle-loading';
import { SSEStatus } from '../../api/chart-api';

export type ChartData = { timestamp: string, value: number }[];
type TradingViewAreaChartProps = {
    data: ChartData;
    statusChart: SSEStatus;
}

const TradingViewAreaChart: FC<TradingViewAreaChartProps> = ({ data, statusChart }) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartInstanceRef = useRef<IChartApi | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const initializeChart = () => {
            if (!chartContainerRef.current || chartInstanceRef.current) return;

            chartInstanceRef.current = createChart(chartContainerRef.current, {
                crosshair: {
                    vertLine: {
                        visible: false,
                    },
                    horzLine: {
                        visible: false,
                    }
                },
                layout: {
                    textColor: theme.colors.surface,
                    background: { type: ColorType.Solid, color: 'transparent' }
                },
                grid: {
                    vertLines: {
                        visible: false
                    },
                    horzLines: {
                        color: 'rgba(255, 255, 255, 0.2)',
                        style: LineStyle.Dashed,
                    },
                },
                handleScroll: false,
                handleScale: false,
                rightPriceScale: {
                    scaleMargins: {
                        top: 0.2,
                        bottom: 0.2, // Отступ снизу
                    },
                    ticksVisible: true,
                },
            });
        };

        const renderData = () => {
            if (!chartInstanceRef.current || !data.length) return;

            const areaSeries = chartInstanceRef.current.addAreaSeries({
                topColor: 'rgba(13, 198, 160, 0.4)',
                bottomColor: 'rgba(122, 186, 173, 0.4)',
                lineColor: 'rgba(13, 198, 160, 1)',
                lineWidth: 2,

            });

            const formattedData = data.reduce((acc: { time: number; value: number }[], point) => {

                const [datePart, timePart] = point.timestamp.split(" ");
                const [year, month, day] = datePart.split("-").map(Number);
                const [hours, minutes, seconds] = timePart.split(":").map(Number);
                const monthIndex = month - 1;

                const unixTime = new Date(Date.UTC(year, monthIndex, day, hours, minutes, seconds)).getTime() / 1000;

                if (unixTime !== acc[acc.length - 1]?.time) {
                    acc.push({ time: unixTime, value: point.value });
                }

                return acc;
            }, []);

            //@ts-ignore
            areaSeries.setData(formattedData);

            const timeScale = chartInstanceRef.current.timeScale();
            timeScale.applyOptions({
                timeVisible: true,     
                secondsVisible: true,  
                ticksVisible: true,    
                barSpacing: 15,
                rightOffset: 0,        
                fixRightEdge: true,    
            });
            timeScale.fitContent();
        };

        initializeChart();
        renderData();
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.remove();
                chartInstanceRef.current = null;
            }
        };
    }, [data]);

    useEffect(() => {
        if (statusChart !== "ready") {
            setIsLoading(true);
        }
        else setIsLoading(false);
    }, [statusChart])

    return <div ref={chartContainerRef} style={{ width: '100%', height: '100%', padding: "10px 0px 0px 0px" }}>
        {isLoading && <CircleLoading state={'loading'} />}
    </div>;
};

export default TradingViewAreaChart;
