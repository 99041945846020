import { FC, HTMLAttributes, memo, useRef, useState } from "react";
import { StyledImage } from "./image.styles";
import { Skeleton } from "../../сomponents/skeleton";


export type ImageProps = {
    src: string;
    size?: Number;
    width?: Number;
    height?: Number;
    alt?: string;
    className?: string;
    themeColor?: string;
} & HTMLAttributes<HTMLDivElement>;

export const CustomImage: FC<ImageProps> = memo(({ size, src, alt, className, width, height, themeColor, ...rest }) => {

    const imageRef = useRef<HTMLImageElement | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const image = new Image();
    image.src = src;

    imageRef.current = image;

    imageRef.current.onload = () => {
        setLoading(false);
    }

    console.log(rest.style?.borderRadius);

    return (
        <Skeleton
            isSkeleton={loading}
            width={`${size}px` }
            height={`${size}px` }
            children={
                <StyledImage ref={imageRef} size={size} themeColor={themeColor} width={width ? width : 20} height={height ? height : 20} src={src} alt={alt} className={className} {...rest} />}
            style={{ borderRadius: rest.style?.borderRadius || '10px' }}
            {...rest}
        />
    )
});
