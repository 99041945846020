
import { CSSProperties, FC, memo } from 'react';
import { Column } from '../../../../ui-kit/column';
import { Surface } from '../../../../ui-kit/surface';
import { Row } from '../../../../ui-kit/row';
import { Spacing } from '../../../../ui-kit/spacing';
import { Text } from '../../../../ui-kit/text';
import { Image } from '../../../../ui-kit/image';
import { Pressable } from '../../../../ui-kit/pressable';
import { themes } from '../../../../themes/theme';
import { Blur, GridPlayers, GridTopCard, ScrollContainer, Slice } from './swiper-leaderboard.styled';
import { Leader } from '../../../../store/reducers/swiper/swiper-leaderboard-slice';

export type SwiperLeaderboardViewProps = {
  leaders: Leader[];
  topThree: Leader[];
  changeFilter: () => void;
  filter: "PLAYERS" | "FARMERS";
};
export const SwiperLeaderboardView: FC<SwiperLeaderboardViewProps> = memo(({ leaders, topThree, changeFilter, filter }) => {
  const filterTop = topThree.map((item, index) => {
    return { ...item, place: index + 1 }
  }).sort((a, b) => {
    const order: { [key: number]: number } = { 2: -1, 1: 0, 3: 1 };
    return order[a.place] - order[b.place];
  });

  return (
    <Column style={{ height: "90%", position: "relative" }} horizontalAlign="center">
      <Blur />
      <Spacing themeSpace={14} variant="Column" />
      <Text themeFont={themes.fonts.h1}>Leaderboard</Text>
      <Spacing themeSpace={14} variant="Column" />
      <Column horizontalAlign="center" style={{ maxWidth: "300px" }}>
        <ToggleMobile state={filter} onClick={changeFilter} />
      </Column>
      <Spacing themeSpace={20} variant="Column" />
      <ScrollContainer>
        <GridTopCard>
          {filterTop.map((item) =>
            <CardTopPlayer
              max={item.place === 1 ? true : false}
              img={item.img}
              name={item.name}
              amount={item.amount}
              currency={item.currency}
              place={item.place}
            />
          )}
        </GridTopCard>
        <Spacing themeSpace={60} variant="Column" />
        <GridPlayers>
          {leaders.map(item =>
            <>
              <CardPlayer
                key={item.name + item.place}
                place={item.place}
                name={item.name}
                amount={item.amount}
                currency={item.currency}
                img={item.img}
              />
              <Spacing themeSpace={5} variant="Column" />
            </>
          )}
        </GridPlayers>
      </ScrollContainer>
    </Column>
  );
});

type CardTopPlayerProps = {
  max: boolean
  img: string;
  name: string
  amount: string;
  currency: string;
  place: number;
}
const CardTopPlayer: FC<CardTopPlayerProps> = memo(({ max, img, name, amount, currency, place }) => {
  const textNameStyle: CSSProperties = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    textAlign: "center"
  }
  return (
    <Column style={{ width: "auto" }}>
      <Surface
        radius={60}
        width={max ? 100 : 70}
        height={max ? 100 : 70}
        themeColorBorder={max ? "linear-gradient(90deg, rgba(13,198,160,1) 0%, rgba(210,56,176,1) 100%) border-box;" : undefined}
      >
        <Column style={{ height: "100%", position: "relative" }} horizontalAlign="center" verticalAlign="center">
          <Image style={{ borderRadius: "50%" }} src={img} size={max ? 85 : 55} />
          <Surface
            style={{ position: "absolute", bottom: "-10px", zIndex: 10, }}
            radius={50}
            width={21}
            height={21}
            themeColorBorder={max ? "linear-gradient(90deg, rgba(13,198,160,1) 0%, rgba(210,56,176,1) 100%) border-box;" : undefined}
          >
            <Column style={{ height: "100%" }} horizontalAlign="center" verticalAlign="center">
              <Text themeFont={themes.fonts.h5} themeColor={themes.colors.secondary}>
                {place}
              </Text>
            </Column>
          </Surface>
        </Column>
        <Spacing themeSpace={15} variant="Column" />
        <Text style={textNameStyle} align="center" themeFont={themes.fonts.h5}>
          {name}
        </Text>
        <Spacing themeSpace={4} variant="Column" />
        <Text align="center" themeColor={themes.colors.primary} themeFont={themes.fonts.ht2}>
          {amount} {currency}
        </Text>
      </Surface>
    </Column>
  );
})

type ToggleMobileProps = {
  state: "PLAYERS" | "FARMERS";
  onClick: () => void;
}

const ToggleMobile: FC<ToggleMobileProps> = memo(({ state, onClick }) =>
  <Row verticalAlign="center" horizontalAlign="space-around" style={{ width: '100%', zIndex: 5 }}>
    <Pressable onClick={onClick}>
      <Text
        themeColor={state === "PLAYERS" ? themes.colors.primary : themes.colors.foreground}
        themeFont={themes.fonts.ht1}>
        PnL
      </Text>
    </Pressable>
    <Slice />
    <Pressable onClick={onClick}>
      <Text
        themeColor={state === "FARMERS" ? themes.colors.primary : themes.colors.foreground}
        themeFont={themes.fonts.ht1}
      >
        BTRT
      </Text>
    </Pressable>
  </Row>)

type CardPlayerProps = {
  place: number;
  img: string;
  name: string;
  amount: string;
  currency: string;
};

const CardPlayer: FC<CardPlayerProps> = memo(({ place, img, name, amount, currency }) => {
  return (
    <Surface
      themeColorBorder={place === 1 ? "linear-gradient(90deg, rgba(13,198,160,1) 0%, rgba(210,56,176,1) 100%) border-box;" : undefined}
      style={{ padding: "10px" }}
    >
      <Row horizontalAlign="space-between" verticalAlign="center" style={{ width: "100%" }}>
        <Row verticalAlign="center">
          <Text themeFont={themes.fonts.h4}>{place}.</Text>
          <Spacing themeSpace={10} variant="Row" />
          <Image style={{ borderRadius: "50%" }} src={img} size={35} />
          <Spacing themeSpace={10} variant="Row" />
          <Text style={{ overflow: "hidden", overflowWrap: "anywhere" }} themeFont={themes.fonts.h4}>{name}</Text>
        </Row>
        <Row>
          <Text style={{ textAlign: "right" }} themeColor={themes.colors.primary} themeFont={themes.fonts.ht2}>{amount}<br></br>{currency}</Text>
        </Row>
      </Row>
    </Surface>
  )
})
